<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :defaultQueryParam="defaultQueryParams"
      @modelShow="handleModelShow"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCumsMetaPage"
      :deleteFun="deleteCumsMetaInfo"
      :createFun="createCumsMetaInfo"
      :updateFun="updateCumsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getCumsMetaPage, createCumsMetaInfo, updateCumsMetaInfo, deleteCumsMetaInfo } from '@/api/cums'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      tenantOps: [],
      queryFields: [
        { label: '贸易方式', name: 'name', fieldType: '', queryType: '%' }
      ],
      defaultQueryParams: {
        type: 5
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '贸易方式',
          dataIndex: 'name'
        },
        {
          title: '简码',
          dataIndex: 'short_code'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写贸易方式', trigger: 'blur' }
        ]
      },
      modelTitle: '贸易方式',
      formFields: [
        {
          label: '贸易方式', name: 'name', type: 'default', ref: 'name'
        },
        // {
        //   label: '贸易方式', name: 'short_name', type: 'default', ref: 'short_name'
        // },
        {
          label: '简码', name: 'short_code', type: 'default'
        },
        {
          label: '', name: 'type', type: 'hidden', defaultValue: 5
        }
      ],
      moduleName: 'cums_meta_info'
    }
  },
  methods: {
    getCumsMetaPage,
    createCumsMetaInfo,
    updateCumsMetaInfo,
    deleteCumsMetaInfo,
    handleModelShow(a) {
      const nameObj = a.$refs['name'][0].$refs.input
      nameObj.oninput = () => {
        a.originForm.short_code = getFirstLetter(a.originForm.name)
      }
    }
  }
}
</script>

<style>
  .rent .ant-form .ant-col-12{
    float: right
  }
</style>
